<template>
  <div class="components-form-advanced-search">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='状态'>
            <a-select v-model="searchForm.Status">
              <a-select-option value="0">锁定</a-select-option>
              <a-select-option value="1">启用</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='用户名'>
            <a-input placeholder="用户名" v-model="searchForm.Username"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='姓名'>
            <a-input placeholder="姓名" v-model="searchForm.Name" />
          </a-form-item>
        </a-col>
        <a-col
          v-if="$root.$data.privateState.systemRole !== 'insurance'"
          :span="6"
          style="display:block;"
        >
          <a-form-item label='机构' v-if="!noOrganizationTree">
            <a-tree-select
              showSearch
              :value="searchForm.OrganizationUuid"
              @change="onChangeSelectedChannels"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="请选择"
              allowClear
              multiple
              :treeData="organizationTree"
            >
            </a-tree-select>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          :style="{ display: expand ? 'block' : 'none' }"
        >
          <a-form-item label='手机号'>
            <a-input placeholder="手机号" v-model="searchForm.Phone" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick">
            查询
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="expand = !expand">
            {{expand ? '收起' : '展开'}} <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    
    
    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-col>
          <a-button icon="plus" type="primary" @click="addUserClick" v-if="$root.$data.privateState.action.includes('/Account/Create') || $root.$data.privateState.role === 'SuperAdmin'">添加用户</a-button>
          <a-divider type="vertical" />
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="column-height" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'default' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'default'">默认</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'middle' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'middle'">中等</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'small' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'small'">紧凑</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="() => {screenfull.toggle();isFullscreen=!isFullscreen}"/>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" icon="reload" @click="searchClick" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group :value="showColumns" @change="changeColumns">
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{it}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="setting" />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        v-if="$root.$data.privateState.action.includes('/Account/TableList') || $root.$data.privateState.role === 'SuperAdmin'"
        :columns="columns"
        :rowKey="record => record.Uuid"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 750 }"
        @change="handleTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #status="status">
          <a-tag :color="$statusEnum.userStatus[status].color">{{$statusEnum.userStatus[status].text}}</a-tag>
        </template>
        <template #role="role">
          <a-tag color="cyan">{{role}}</a-tag>
        </template>
        <template #channelName="channelName">
          <a-tag color="cyan" v-if="channelName">{{channelName}}</a-tag>
          <a-tag color="purple" v-else>平台建立</a-tag>
        </template>
        <template #organizationName="organizationName">
          <a-tag color="cyan" v-if="organizationName">{{organizationName}}</a-tag>
          <a-tag color="purple" v-else>平台建立</a-tag>
        </template>
        <template #action="data">
          <a href="javascript:;" @click="editUserClick(data)" v-if="$root.$data.privateState.action.includes('/Account/Edit') || $root.$data.privateState.role === 'SuperAdmin'">编辑</a> | 
          <a-popconfirm
            v-if="$root.$data.privateState.action.includes('/Account/Delete') || $root.$data.privateState.role === 'SuperAdmin'"
            title="是否确认删除该用户?"
            @confirm="deleteUserClick(data)"
            okText="是"
            cancelText="否"
          >
            <a href="javascript:;">删除</a>
          </a-popconfirm>
          
        </template>
      </a-table>
    </div>
    <!-- table end -->
    <a-modal width="80%" :title="createAndEditModalTitle" v-model="createAndEditShow" @ok="createAndEditModalOk">
      <a-form-model ref="formRef" :model="form" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-row :gutter="6">
          <a-col :span="8">
            <a-form-model-item label="姓名" prop="name">
              <a-input v-model="form.name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="手机号" prop="phone">
              <a-input v-model="form.phone" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="用户名" prop="username">
              <a-input v-model="form.username" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="密码" prop="password">
              <a-input v-model="form.password" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="登录模式" prop="loginType">
              <a-select style="width: 100%" v-model="form.loginType">
                <a-select-option value="Multiple">多点登录</a-select-option>
                <a-select-option value="Single">单点登录</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="状态" prop="status">
              <a-select style="width: 100%" v-model="form.status">
                <a-select-option value="0">锁定</a-select-option>
                <a-select-option value="1">启用</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8" v-if="!isEditOtherPartitionId">
            <a-form-model-item label="角色" prop="role">
              <a-select style="width: 100%" v-model="form.role">
                <a-select-option v-for="d in roleList" :key="d.uuid">{{ d.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="所属机构" prop="organizationUuid">
              <a-tree-select
                placeholder="请选择"
                show-search
                v-model="form.organizationUuid"
                :tree-data="organizationTree"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item :labelCol="{span: 3}" label="可查看订单归属范围" help="包含所选下级">
              <a-tree-select
                placeholder="请选择"
                :treeCheckStrictly="true"
                tree-checkable
                :show-checked-strategy="SHOW_PARENT"
                v-model="form.activeOrgInLabel"
                :tree-data="organizationTree"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item :labelCol="{span: 3}" label="备注" prop="remarks">
              <a-textarea
                v-model="form.remarks"
                placeholder="Controlled autosize"
                :autoSize="{ minRows: 3, maxRows: 5 }"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import screenfull from 'screenfull'
import { Account, Role, Organization } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'
import { TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const INIT_SEARCH_FORM = '{"Status":"","Username":"","OrganizationUuid":[],"Phone":""}'
const columns = [
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '姓名',
    dataIndex: 'name'
  },
  {
    title: '用户名',
    dataIndex: 'username',
  },
  {
    title: '手机号',
    dataIndex: 'phone'
  },
  {
    title: '所属渠道机构',
    dataIndex: 'organizationName',
    scopedSlots: { customRender: 'organizationName' },
  },
  {
    title: '角色',
    dataIndex: 'roleName',
    scopedSlots: { customRender: 'roleName' },
  },
  {
    title: '操作',
    key: 'operation',
    width: 230,
    scopedSlots: { customRender: 'action' },
  }
];
export default {
  name: 'SystemUsers',
  mixins: [aliveVM, mixinTableList],
  created () {
    this.fetch()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.init()
    })
  },
  computed: {
    showColumns() {
      let c = []
      for (const it of this.columns) {
        c.push(it.title)
      }
      return c
    }
  },
  data () {
    return {
      SHOW_PARENT,
      screenfull,
      isFullscreen: false,
      expand: false,
      noOrganizationTree: false,
      noRoleList: false,
      tableListApi: `${Account}/TableList`,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      labelCol: { span: 9 },
      wrapperCol: { span: 15 },
      roleList: [],
      baseColumns: [],
      columns,
      isEditOtherPartitionId: false,
      createAndEditModalTitle: '添加用户',
      createAndEditShow: false,
      createAndEditForm: this.$form.createForm(this),
      nowFormType: '',
      nowEditUuid: '',
      nowEditData: {},
      organizationTree: [],
      form: {
        name: '',
        username: '',
        password: '',
        phone: '',
        loginType: '',
        status: '',
        type: '',
        role: '',
        organizationUuid: '',
        remarks:'',
        activeOrgInLabel: [],
        activeOrg: []
      },
      formRules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        username: [{ required: true, message: '请输入', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入', trigger: 'blur' }],
        loginType: [{ required: true, message: '请输入', trigger: 'change' }],
        status: [{ required: true, message: '请输入', trigger: 'change' }],
        role: [{ required: true, message: '请输入', trigger: 'change' }],
        organizationUuid: [{ required: true, message: '请输入', trigger: 'change' }],
      },
    }
  },
  methods: {
    async init () {
      const baseColumns = []
      for (const it of columns) {
        baseColumns.push(it.title)
      }
      this.$set(this, 'baseColumns', baseColumns)
      const roleRes = await this.$axios.get(`${Role}/GetSelectList`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!roleRes.status) {
        this.noRoleList = true
      } else {
        this.$set(this, 'roleList', roleRes.data)
      }
      const orgRes = await this.$axios.get(`${Organization}/GetTree`)
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
        if (!orgRes.status) {
          this.$message.error(`获取组织树：${orgRes.message}`)
          this.noOrganizationTree = true
        } else {
          this.$set(this, 'organizationTree', orgRes.data)
        }
    },
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    onChangeSelectedChannels (v) {
      this.$set(this.searchForm, 'OrganizationUuid', v)
    },
    async reload() {
      this.$set(this, 'searchParams', null)
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', null)
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      this.pagination.current = 1
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      if (searchForm.Status !== '') {
        searchForm.Status = parseInt(searchForm.Status)
      }
      const searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    addUserClick () {
      if (this.noOrganizationTree) {
        this.$message.error('缺少必要的组织列表获取权限')
        return
      }
      if (this.noRoleList) {
        this.$message.error('缺少必要的角色列表获取权限')
        return
      }
      const form = {
        name: '',
        username: '',
        password: '',
        phone: '',
        loginType: '',
        status: '',
        type: '',
        role: '',
        organizationUuid: '',
        remarks:'',
        activeOrgInLabel: [],
        activeOrg: []
      }
      this.createAndEditModalTitle = '添加用户'
      this.$set(this, 'form', form)
      this.$set(this, 'nowFormType', 'create')
      this.$set(this, 'isEditOtherPartitionId', false)
      this.createAndEditShow = true
    },
    createAndEditModalOk () {
      this.$refs.formRef.validate(async valid => {
        if (!valid) {
          this.$message.error('请检查填写内容');
          return
        }
        const postData = JSON.parse(JSON.stringify(this.form))
        console.log(postData)
        if (postData.activeOrgInLabel.length > 0) {
          const activeOrg = []
          for (const it of postData.activeOrgInLabel)
          {
            activeOrg.push(it.value)
          }
          postData.activeOrg = activeOrg
        }
        if (this.nowFormType === 'create') {
          if (licia.isEmpty(postData.password)) {
            this.$message.error('新建用户必须填写密码')
            return
          }
          const apiRes = await this.$axios.post(`${Account}/Create`, postData)
            .catch(e => {
              return {
                status: false,
                message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
              }
            })
          if (!apiRes.status) {
            this.$message.error(apiRes.message)
            return
          }
          this.createAndEditShow = false
          this.$message.success('新建用户成功')
          this.fetch()
        } else if (this.nowFormType === 'edit') {
          const apiRes = await this.$axios.put(`${Account}/Edit?uuid=${this.nowEditUuid}`, postData)
            .catch(e => {
              return {
                status: false,
                message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
              }
            })
          if (!apiRes.status) {
            this.$message.error(apiRes.message)
            return
          }
          this.createAndEditShow = false
          this.$message.success('编辑用户成功')
          this.fetch()
        }
      })
    },
    editUserClick (data) {
      if (this.noOrganizationTree) {
        this.$message.error('缺少必要的组织列表获取权限')
        return
      }
      if (this.noRoleList) {
        this.$message.error('缺少必要的角色列表获取权限')
        return
      }
      this.$set(this, 'nowEditData', data)
      this.$set(this, 'isEditOtherPartitionId', false)
      if (data.partitionId !== this.$root.$data.privateState.partitionId) {
        this.$set(this, 'isEditOtherPartitionId', true)
      }
      const form = {
        name: data.name,
        username: data.username,
        password: '',
        phone: data.phone,
        role: data.role,
        status: `${data.status}`,
        organizationUuid: data.organizationUuid,
        remarks: data.remarks,
        loginType: data.loginType,
        activeOrgInLabel: data.activeOrgInLabel ? data.activeOrgInLabel : [],
        activeOrg: data.activeOrg ? data.activeOrg : [],
      }
      this.$set(this, 'form', form)
      this.$set(this, 'nowFormType', 'edit')
      if (data.partitionId !== this.$root.$data.privateState.partitionId) {
        this.$set(this, 'isEditOtherPartitionId', true)
      }
      this.nowEditUuid = data.uuid
      this.createAndEditModalTitle = '编辑用户'
      this.createAndEditShow = true
    },
    async deleteUserClick (data) {
      const apiRes = await this.$axios.delete(`${Account}/Delete?uuid=${data.uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message)
        return
      }
      this.fetch()
    }
  }
}
</script>

<style>

</style>